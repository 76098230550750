<template>
  <div class="PaymentMethodsHubListener">
    <ui-loader
      v-if="isLoading"
      absolute
    />
    <FeedbackModalComponent
      v-else
      v-model="modals.active.isOpen"
      v-bind="modals.active"
    />
  </div>
</template>

<script>
import some from 'lodash/some';
import has from 'lodash/has';

import { mapActions, mapState } from 'vuex';

import {
  camelCaseKeys, LOG_TYPE, logger, navigationErrorHandler,
} from '@emobg/web-utils';

import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { useStore } from 'vuex-composition-helpers/dist';
import { commonImplementationHelper } from './commonImplementationHelper';
import { PaymentMethodsHubComposable } from './composables/PaymentMethodsHubComposable';
import {
  ACTIONS,
  BOOKING_PREVIOUS_PAGE,
  DEFAULT_PAYMENT_PROVIDER,
  DEFAULT_TRACKING_PROVIDER,
  HIERARCHY,
  HUB_REDIRECT_FLOW,
  HUB_REDIRECT_FLOW_STATUS,
  MATCHING_NAME,
  ORIGIN,
  PAYMENT_HUB_CURRENT_URL,
  PROVIDER_USED,
  RESPONSE_STATUS,
  SOURCE_STORE,
} from './constants/paymentMethodsHub';

import { nameSpace as PaymentMethodsHubNameSpace } from './stores/PaymentMethodsHub';

import { onResponseParent } from './composables/onResponse';

export default {
  name: 'PaymentMethodsHubListener',

  components: {
    FeedbackModalComponent,
  },

  props: {
    provider: {
      type: String,
      default: DEFAULT_PAYMENT_PROVIDER,
    },

    stopEventPropagation: {
      type: Boolean,
      default: false,
    },

    trackingProviderFn: {
      type: Function,
      default: () => {
        const defaultTrackingProvider = DEFAULT_TRACKING_PROVIDER;
        return import(`${defaultTrackingProvider}`);
      },
    },
  },

  setup(props) {
    const { storeData } = commonImplementationHelper();

    const { getUtilsFromStoredProvider, storage, parseResponse } = PaymentMethodsHubComposable(storeData);

    const { onComposableResponse, onFromRedirect } = onResponseParent(storeData);

    const store = useStore();

    const trackingProvider = props.trackingProviderFn;

    const providerUsed = storage.get(PROVIDER_USED);

    return {
      getUtilsFromStoredProvider,
      storeData,
      storage,
      parseResponse,
      onFromRedirect,

      onComposableResponse,
      trackingProvider,
      store,

      providerUsed,
    };
  },

  data() {
    return {
      isLoading: false,
      response: null,
      urlQuery: null,
      modals: {
        active: {
          isOpen: false,
        },
      },
    };
  },

  computed: {
    ...mapState(PaymentMethodsHubNameSpace, [
      'lastResponse',
    ]),
  },

  watch: {
    async lastResponse(response) {
      const parsedResponse = this.parseResponse(response);

      const additionalStepResponses = [RESPONSE_STATUS.challenge, RESPONSE_STATUS.identify];

      await this.updateHubAction({
        action: 'toggleHelperComponentLoader', value: additionalStepResponses.includes(response.providerStatus),
      });

      this.onResponse(parsedResponse);
    },
  },

  async mounted() {
    await this.checkRedirect();

    if (this.storage.get(HUB_REDIRECT_FLOW) === HUB_REDIRECT_FLOW_STATUS.redirectReSent) {
      this.$router.push({ query: { status: HUB_REDIRECT_FLOW_STATUS.redirectStep0 } }).catch(navigationErrorHandler);
    }

    this.storage.add(PAYMENT_HUB_CURRENT_URL, window.location.pathname);
  },

  beforeDestroy() {
    this.storage.remove(PAYMENT_HUB_CURRENT_URL);
    this.storage.remove(BOOKING_PREVIOUS_PAGE);
  },

  created() {
    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('modal.add_payment_method.success.title'),
      description: '',
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
      },
    };

    this.errorModalArgs = {
      ...genericErrorArgs(this.$t),
      title: this.$t('modal.confirm_payment.error.title'),
      description: '',
    };
  },

  methods: {
    ...mapActions(PaymentMethodsHubNameSpace, ['updateSource', 'updateHubAction']),

    async checkRedirect() {
      if (this.$router.history.current.fullPath.includes(HUB_REDIRECT_FLOW_STATUS.toRedirect)) {
        this.$router.push({ query: {} }).catch(navigationErrorHandler);
      }

      if (!this.providerUsed) {
        return;
      }

      const utils = await this.getUtilsFromStoredProvider(this.providerUsed);

      if (!utils) {
        logger.message('no utils found, exiting checkRedirect...');
        return;
      }

      logger.message(`using ${this.providerUsed} as providerUsed`);

      const { redirectConfirmQuery, redirectQuery } = utils;
      const currentPath = this.$route.fullPath;

      const isRedirectInQueryParams = some([redirectConfirmQuery, redirectQuery], queryString => has(this.$route.query, queryString));

      if (isRedirectInQueryParams) {
        this.$emit('update:init-redirect-flow');
        this.isLoading = true;
        await this.onFromRedirect(utils, currentPath, this.storeData, this.onResponse);

        this.isLoading = false;

        this.$router.push({ query: { status: HUB_REDIRECT_FLOW_STATUS.redirectStep0 } }).catch(navigationErrorHandler);
      } else if (this.providerUsed) {
        logger.message('no matching query params', LOG_TYPE.warning);
        logger.message('removing provider', LOG_TYPE.warning);
        this.storage.remove(PROVIDER_USED);
      }

      this.storage.remove(HIERARCHY);
    },

    async onResponse(primaryResponse, origin) {
      if (!primaryResponse) {
        return;
      }

      const response = camelCaseKeys(primaryResponse);
      if (origin === ORIGIN.redirect) {
        this.$emit('update:end-redirect-flow');
      }

      const matchingName = this.storage.get(MATCHING_NAME) || response?.matchingName;
      this.storage.remove(MATCHING_NAME);

      let internalResponse = response.status || response.providerStatus || response.provider_status;

      if (origin === ORIGIN.redirect && response.number && response.expiry) {
        internalResponse = RESPONSE_STATUS.redirectAuthorised;
      }

      if (origin === ORIGIN.forcedError) {
        internalResponse = RESPONSE_STATUS.refused;
      }

      const updateCommonHubActions = async () => {
        await this.updateHubAction({ action: 'resetFlow' });
        await this.updateHubAction({ action: 'toggleHelperComponentLoader', value: false });
      };

      const trackAddPaymentMethodSuccess = async () => {
        const { paymentMethodsHubTracking } = await this.trackingProvider();

        const { addPaymentMethodAuthorisedTrackEvent } = await paymentMethodsHubTracking(this.store);
        const { trackingLibrary, addPaymentMethodAuthorisedEvent } = addPaymentMethodAuthorisedTrackEvent();

        trackingLibrary(addPaymentMethodAuthorisedEvent);
      };

      const authoriseFlow = async () => {
        if (this.stopEventPropagation || response.actionUsed === ACTIONS.add) {
          trackAddPaymentMethodSuccess();
          this.showSuccessFeedback();

          if (response.actionUsed === ACTIONS.add) {
            await this.updateHubAction({
              action: 'linkDefaultPaymentMethodOnAdd',
              value: response,
            });
          }
        } else {
          this.$emit('on:payment-authorised', {
            ...response,
            matchingName,
          });
        }

        this.updateSource(SOURCE_STORE.listener);
        updateCommonHubActions();
      };

      const refuseFlow = () => {
        if (this.stopEventPropagation || response.actionUsed === ACTIONS.add) {
          this.showRefusedFeedback(response);
        } else {
          this.$emit('on:payment-refused', {
            ...response,
            matchingName,
          });
        }

        updateCommonHubActions();
      };

      switch (internalResponse) {
        case RESPONSE_STATUS.authorised:
        case RESPONSE_STATUS.redirectAuthorised:
          authoriseFlow();
          break;

        case RESPONSE_STATUS.refused:
        case RESPONSE_STATUS.error:
          refuseFlow();
          break;

        default:
          logger.message(`unexpected response: ${response.data || response}`, LOG_TYPE.error);
          break;
      }
    },

    showSuccessFeedback() {
      this.modals.active = {
        ...this.successModalArgs,
        isOpen: true,
      };
    },

    showRefusedFeedback(response) {
      const fallbackTitle = response.actionUsed === ACTIONS.add
        ? this.$t('modal.add_payment_method.error.title')
        : this.$t('modal.confirm_payment.error.title');

      this.modals.active = {
        ...this.errorModalArgs,
        title: response.provider_message || fallbackTitle,
        isOpen: true,
        primaryCallToAction: () => {
          this.modals.active.isOpen = false;
          this.$emit('close:feedback-modal', RESPONSE_STATUS.refused);
        },
      };
    },
  },
};
</script>
